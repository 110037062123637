@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("core/styles/scrollbar.css");

html, body, #root {
  position: relative;
  margin: 0;
  height: 100%;
  min-height: 100%;
}
